import PlugsResolver from '@components/plugs/PlugResolver';
import { getClient } from '@utils/sanity/sanity.server';
import { GetStaticPaths, GetStaticProps } from 'next';
import { FunctionComponent } from 'react';

import EmptyPage from '../components/EmptyPage';
import globalQuery from '../utils/queries/globalQuery';
import routePathsQuery from '../utils/queries/routePathsQuery';
import routeQuery from '../utils/queries/routeQuery';

type Props = {
  data;
  preview;
};

const Index: FunctionComponent<Props> = ({ data }) => {
  const content = data?.content ?? {};

  return <>{content ? <PlugsResolver plugs={content} /> : <EmptyPage text="Empty page" />}</>;
};

export const getStaticPaths: GetStaticPaths = async () => {
  const data = await getClient().fetch(routePathsQuery);

  const paths = data.map((route, key) => ({
    params: { id: key, slug: route.slug.current.toString() },
  }));

  return { paths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps = async ({ params, preview = false }) => {
  const slug = params?.slug ?? ``;

  const queryParams = { slug };

  if (preview) {
    return { props: { preview, data: { queryParams } } };
  }

  let data = await getClient(preview).fetch(routeQuery, { slug });
  let global = await getClient(preview).fetch(globalQuery);

  if (Array.isArray(global)) {
    global = global.slice(-1)[0];
  }

  if (Array.isArray(data)) {
    data = data.slice(-1)[0];
  }

  // If no data exists, skip revalidation to see if this page is just been added
  if (!data) {
    return {
      props: { data: null, global, preview },
      revalidate: false,
    };
  }

  return {
    props: { data, global, preview },
    revalidate: 60 * 20, // 20 minutes
  };
};

export default Index;
